import qs from "query-string";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import api from "../lib/api";
import ListPagination from "../components/ListPagination";
import { useEffect, useState } from "react";
import SearchBar from "../components/SearchBar";
import { Link, useNavigate } from "react-router-dom";
import { LinkButton } from "../components/Button";

export default function BookList() {
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [keyword, setKeyword] = useState({});
  const navigate = useNavigate();

  const { isLoading, error, data, refetch } = useQuery({
    queryKey: [`BookList`, { page, size, keyword }],
    queryFn: () =>
      axios
        .post(
          `/api/book/list`,
          qs.stringify({ page, size }) + "&" + qs.stringify(keyword),
          {
            headers: {
              Authorization: "bearer " + api.getToken(),
            },
          }
        )
        .then((res) => {
          return res.data.data;
        })
        .catch((res) => window.alert(res)),
  });

  const bookList = data?.items?.map((item) => {
    return (
      <tr
        key={item.id}
        onClick={() => navigate(`/item/${item.id}`)}
        className="text-center text-sm border-b border-gray-300 border-dashed hover:bg-gray-100 cursor-pointer last-of-type:border-solid *:p-2"
      >
        <td className="text-xs">{item.id}</td>
        <td>{item.prodDate}</td>
        <td>{item.bookId}</td>
        <td>{item.grade}</td>
        <td>{item.publisher}</td>
        <td>{item.creator}</td>
        <td>{item.title}</td>
        <td>{item.curriculum}</td>
        <td>{item.permission}</td>
        <td>{item.purpose}</td>
        <td>
          {(item.type === "brl" && "점자") ||
            (item.type === "spe" && "음성") ||
            (item.type === "mag" && "확대")}
        </td>
        <td className="text-xs">{item.pubDate}</td>
        <td className="text-xs">{item.isbn}</td>
      </tr>
    );
  });

  return (
    <>
      <div className="lg:w-3/4 lg:mx-auto p-4 lg:p-0 mb-12">
        <div className="font-bold text-2xl my-8">목록 검색</div>
        <SearchBar setKeyword={setKeyword} setPage={setPage} />
        {isLoading ? (
          <div>Loading...</div>
        ) : error ? (
          <div>{error.message}</div>
        ) : (
          <>
            <table className="mx-auto w-full table-auto">
              <thead>
                <tr className="">
                  <td colSpan={3} className="pb-2 text-xs text-left">
                    검색 결과: {data.totalCount} 건
                  </td>
                </tr>
                <tr className="bg-gray-200 text-center *:bg-gray-200 *:py-2 *:text-sm *:font-bold ">
                  <td>No.</td>
                  <td>제작일</td>
                  <td>도서 ID</td>
                  <td>학교급</td>
                  <td>발행사</td>
                  <td>저자</td>
                  <td>도서명</td>
                  <td>교육과정</td>
                  <td>국검인</td>
                  <td>교과용도</td>
                  <td>제작종류</td>
                  <td>발행일</td>
                  <td>isbn</td>
                </tr>
              </thead>
              <tbody>{bookList}</tbody>
              <tfoot></tfoot>
            </table>
            <ListPagination data={data} setPage={setPage} />
            {/* 
            <div className="mb-16 text-right">
              <LinkButton text="신규 등록" address={`/item/create`} />
            </div> */}
          </>
        )}
      </div>
    </>
  );
}
