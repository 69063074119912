import React, { useEffect } from "react";

import { useRecoilState, useSetRecoilState } from "recoil";
import {
  tokenAtom,
  nameAtom,
  nickAtom,
  idAtom,
  userIdAtom,
  uploadStateAtom,
} from "./atom";
import axios from "axios";
import api from "./api";

export let AuthContext = React.createContext({
  login: () => {},
  logout: () => {},
  setUser: () => {},
});

function AuthProvider({ children }) {
  const [token, setToken] = useRecoilState(tokenAtom);
  const setName = useSetRecoilState(nameAtom);
  const setNick = useSetRecoilState(nickAtom);
  const setUserId = useSetRecoilState(userIdAtom);
  const setId = useSetRecoilState(idAtom);
  const setProgress = useSetRecoilState(uploadStateAtom);

  axios.defaults.onUploadProgress = function (progressEvent) {
    const { loaded, total } = progressEvent;
    var percentCompleted = Math.round((loaded * 100) / total);
    setProgress(percentCompleted);
  };

  axios.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      // 오류 응답을 처리
      // ...
      // console.log(error);
      if (error.response.status === 401 || error.response.status === 403) {
        logout();
      }

      return Promise.reject(error);
    }
  );

  const setUser = () => {
    if (api.getUserData()) {
      setToken(api.cookies.get("accessToken"));
      setName(api.getUserData().firstName);
      setNick(api.getUserData().nickName);
      setUserId(api.getUserData().user);
      setId(api.getUserData().id);

      api.cookies.remove("userData", { path: "/" });
    }
  };

  const userDataCheck = () => {
    // console.log("userDataCheck");
    api.checkMe().then((res) => {
      setToken(api.cookies.get("accessToken"));
      setUserId(res.data.user);
      setId(res.data.id);
      setName(res.data.firstName);
      setNick(res.data.nickName);
      // console.log("setting");
    });
  };

  useEffect(() => {
    if (api.isLogin() && !token) userDataCheck();
  }, []);

  const login = async (code) => {
    await api.login(code);
  };

  const logout = () => {
    api.logout();
    setToken(null);
    setName(null);
    setNick(null);

    setId(null);
    setUserId(null);
  };

  let value = {
    setUser,
    login,
    logout,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export default AuthProvider;
